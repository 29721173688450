body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee !important;
}

ul {
  list-style: none;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.1;
  color: #566a7f;
}

h1,
.h1 {
  font-size: calc(1.3625rem + 1.35vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.375rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.2875rem + 0.45vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.625rem;
  }
}

h4,
.h4 {
  font-size: calc(1.2625rem + 0.15vw);
}

@media (min-width: 1200px) {

  h4,
  .h4 {
    font-size: 1.375rem;
  }
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6 {
  font-size: 0.9375rem;
}

.containers {
  display: flex;
}

.contents {
  display: flex;
  flex: 1;
}

.logo img {
  height: auto;
  width: 180px;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #a1acb8 !important;
  font-weight: 300 !important;
}

.url-title {
  color: #566a7f;
  font-weight: 700 !important;
}

.main-card-packages {
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header-page {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  height: calc(75vh - 50px);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.table_button {
  margin-left: 10px;
}

.pagination {
  padding: 10px;
  align-items: center !important;
  text-align: center !important;
}

.pagination button {
  margin-right: 10px;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
  font-family: "Roboto";
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.user-info {
  color: #566a7f;
  font-weight: 700;
  font-size: 20px;
  padding: 10px;
}

.welcome-info {
  font-size: 15px;
  font-weight: 500;
}

.button-services {
  padding: 40px 25px;
  background-color: dodgerblue;
  color: white;
  display: block;
  max-width: 100%;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  height: 175px;
  overflow: hidden;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s;
}

.button-services:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}

.new-contract table,
th {
  width: -webkit-fill-available;
  border: 1px solid black;
}

.new-contract {

  tr,
  td {
    border: 1px solid black !important;
    border-width: 1px !important;
  }
}

.new-contract table {
  width: 100%;
  border-collapse: collapse;
}

.new-contract table th {
  width: 83.3333%;
  /* 10/12 */
  text-align: left;
  border-bottom: 1px solid #000000;
  padding: 8px;
}

.new-contract table td {
  width: 16.6667%;
  /* 2/12 */
  text-align: left;
  border-bottom: 1px solid #000000;
  padding: 8px;
}

.new-contract li {
  list-style: disc;
}

.new-contract h6 {
  color: #3d5e99;
  font-weight: 700;
}

.new-contract .fw-600 {
  font-weight: 600;
}

.new-contract h6 {
  span {
    color: #000000;
    font-weight: 500;
    margin-left: 15px;
  }
}

.new-contract .table span {
  color: #3d5e99;
  font-weight: 700;
  font-size: medium;
  background: transparent;
}

.form-check input:hover {
  cursor: pointer !important;
}

.form-check label:hover {
  cursor: pointer !important;
}

.form-check {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  transition: 0.3s;
}

.form-check:hover {
  background-color: #ddd;
  border-radius: 10px;
  padding: 10px;
}

.form-check-label {
  margin-left: 10px;
}

.form-check .form-check-input {
  float: left;
  margin-left: 0 !important;
}

/*ACORDION*/
.accordion {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  border-radius: 8px;
  margin: 0 auto;
}

.accordion-toggle,
.accordion-header {
  font-size: 15px;
  width: 100%;
  height: 3em;
  margin: 0;
  line-height: 3em;
  cursor: pointer;
}

/*El botón que pliega/despliega*/
.accordion-toggle {
  opacity: 0;
  background-color: transparent;
  z-index: 1;
  margin-bottom: 2px;
}

/*Bloque para el título*/
.accordion-header {
  background-color: #ffffff;
  color: #7f8787;
  position: relative;
  margin-top: -3em;
  padding-left: 10px;
}

/*Bloque replegable con el contenido */
.accordion-content {
  box-sizing: border-box;
  background-color: #f7f7f7;
  color: #7f8787;
  overflow: auto;
  position: relative;
  width: 100%;
  height: 250px;
  margin-bottom: -250px;
  padding: 0 20px;
  transition: margin 0.3s ease 0.1s;
}

.accordion-toggle:checked+.accordion-header+.accordion-content {
  margin-bottom: 0;
  margin-right: 0;
}

.accordion-header::before,
.accordion-toggle:checked+.accordion-header::before {
  content: "+";
  display: inline-block;
  text-align: center;
  width: 1em;
  font-size: 1em;
}

.accordion-toggle:checked+.accordion-header::before {
  content: "-";
}

.accordion-toggle:hover+.accordion-header {
  background-color: #f7f1f1;
}

.accordion-toggle:checked+.accordion-header {
  background-color: #fff;
}

.contenedor {
  height: 70vh;
  margin-left: 30px;
  margin-right: 30px;
  transition: 0.3s;
}

.sidebar {
  width: 250px;
  height: 110vh;
  background-color: #fff;
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  /* Inicialmente oculto */
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#root>div>div.contenedor>nav>a {
  padding-left: 30vw;
  cursor: default;
}

#root>div>div.contenedor>nav>a:hover {
  background-color: transparent;
}

#root>div>div.contenedor {
  margin-left: 260px;
}

#root>div>div.contenedor>nav>button {
  display: none;
}

.menu .active {
  background-color: rgb(0,
      0,
      0,
      0.08);
  /* Cambia el color de fondo según tus necesidades */
}

.sidebar.open {
  transform: translateX(0);
  /* Se muestra al abrir */
}

.sidebar-title {
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sidebar-toggle {
  font-size: 24px;
  cursor: pointer;
  padding: 15px;
  text-align: center;
}

.side-item-container {
  padding: 20px 0 20px 20px;
}

.side-item-container svg {
  color: #c0c0c0;
  margin-right: 10px;
  font-size: 25px;
}

.side-item-container span {
  color: #000;
  font-weight: 500;
}

.side-nav-item {
  transition: 0.2s;
}

.side-nav-item:hover {
  background-color: rgb(0,
      0,
      0,
      0.08);
  /* Cambia el color de fondo según tus necesidades */
  cursor: pointer;
}

.menu {
  list-style: none;
  padding: 0;
}

.menu>li {
  border-bottom: 1px solid rgb(0, 0, 0, 0.05);
}

.menu>li:hover {
  background-color: rgb(0, 0, 0, 0.1);
  cursor: pointer;
}

.submenu {
  animation: slideUp 0.3s ease-in-out forwards;
  /* Aplica la animación al abrir */
  display: none;
  background-color: #fff;
  padding-inline-start: 0px !important;
  max-height: 0;
  /* Inicialmente oculto */
  overflow: hidden;
  /* Para ocultar el contenido que excede el tamaño máximo */
}

.submenu-button {
  transition: 0.2s;
}

.submenu-button:hover {
  background-color: rgb(0, 0, 0, 0.1);
  cursor: pointer;
}

.submenu li {
  margin-left: 30px;
  padding: 10px;
}

.submenu.open {
  display: block;
  animation: slideDown 0.3s ease-in-out forwards;
  max-height: 100%;
}

@keyframes slideDown {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 200px;
  }
}

@keyframes slideUp {
  0% {
    max-height: 200px;
  }

  100% {
    max-height: 0;
  }
}

.user-button {
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 5px 8px 5px 8px;
  font-size: 18px;
  border-radius: 5px;
  transition: 0.2s;
}

.user-button:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.2);
}

.settings-button {
  padding: 5px 8px 5px 8px;
  margin-left: 10px;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  transition: 0.2s;
}

.settings-button:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.2);
}

.icon-profile {
  border-radius: 9999px;
  background-color: #888;
  height: 2.5rem;
  width: 2.5rem;
}

.user-container {
  position: relative;
}

.user-menu {
  position: absolute;
  top: 120%;
  left: -100px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: max-content;
  border-radius: 10px;
}

.user-menu .item {
  text-decoration: none;
  color: #333;
  transition: 0.2s;
  padding: 15px;
}


.user-menu .item:hover {

  text-decoration: none;
  color: #333;
  background-color: rgb(0, 0, 0, 0.1);
  cursor: pointer;
}
.user-menu .item:hover:first-child {
  border-radius: 10px 10px 0 0;
}
.user-menu .item:hover:last-child {
  border-radius: 0 0 10px 10px ;
}
.navbar-top {
  background-color: #2962ff;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-left: -30px;
  margin-right: -30px;
}

.toggle-sidebar-btn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  font-weight: 700;
}

@media (max-width: 1250px) {
  .navbar-top {
    margin-left: -10px;
    margin-right: -10px;
  }

  .contenedor {
    height: 100vh;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.3s;
  }
}

/*DATEPICKER*/
.react-date-picker {
  display: inline-flex;
  position: relative;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 1;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

/* CAMBIO DE COLOR HEADER DE CALENDARIO
.react-datepicker__header {
  text-align: center;
  background-color: #0d6efd !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}*/
.archive-container a {
  text-decoration: none;
}

.archive-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  padding-left: 0px;
  padding-right: 50px;
}

.signMargin{
  padding-right: 50px!important;
}

.archive-icon-folder {
  filter: brightness(1);
  transition: all 0.3s;
}

.archive-icon-folder a {
  font-weight: 600;
  font-size: 18px;
  color: #000;
  text-decoration: none !important;
}

.archive-icon-folder:hover {
  transform: translateY(-6%);
  filter: brightness(0.9);
  cursor: pointer;
}

.btn-form {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-right: 5px;
  background-color: #e0e0e0;
  border: 1px solid #dbdbdb;
  border-radius: 10px 10px 0 0;
  text-align: center;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  font-weight: 600 !important;
  text-transform: uppercase;
  margin-bottom: -10px;
}

.btn-form:first-child {
  margin-left: 11px;
}

.btn-form:last-child {
  margin-right: 11px;
}

.btn-form.active {
  background-color: #fff;
  z-index: 50;
  box-shadow: rgba(0, 0, 0, 0) 0px 8px 24px;
  border: none;
}

.form {
  display: none;
}

.form.active {
  display: block;
  visibility: visible;
  border-radius: 10px;
}

.archive-icon-text {
  text-align: center;
  color: #000;
}

.folio {
  font-weight: 600;
}

.drop-zone {
  max-width: 100%;
  height: 200px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 4px dashed #007295;
  border-radius: 10px;
}

.img-container {
  height: 200px;
  width: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  background-repeat: no-repeat;
}

.icons {
  height: auto;
  width: auto;
  transition: all 0.3s;
  border-radius: 10px;
}

.icons:hover {
  cursor: pointer;
  transform: translateY(-3%);
}

.btn-icons-props {
  top: 0;
  right: 20%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  margin-top: 10px;
}

.icons:hover .btn-icons-props {
  opacity: 1;
  top: 1;
  right: 20%;
  pointer-events: auto;
}

.btn-icons {
  background-color: rgb(0, 0, 0, 0.7);
  margin: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
  justify-content: center;
  text-align: center;
  padding: 10px;
  padding-top: 5px;
}

.btn-icons-danger {
  background-color: rgba(206, 0, 0, 0.7);
  margin: 5px;
  padding: 10px;
  padding-top: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
}

.btn-forms {
  background-size: auto;
  height: 15rem;
  margin: 0 30px 60px 30px;
  width: 80%;
  height: 300px;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
}

.label-card {
  text-decoration: none !important;
  padding: 0 24px 12px;
  color: #ffffff;
  font-size: large;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transform: translateY(calc(100% - 50px));
  -webkit-transform: translateY(calc(100% - 38px));
  transition: all 0.4s ease-in-out;
  background-color: rgb(0, 0, 0, 0.8);
  width: 100%;
  border-radius: 0 0px 10px 10px;
}

.input-date {
  border-radius: 0px 10px 10px 0px !important;
}

#root > div > div.contenedor > div:nth-child(3) > div{
  padding: 0 30px;
}

#root > div > div.contenedor > div:nth-child(3) > div > div:nth-child(2) > div.col-lg-9 > div > div > div.main-card{
  height: calc(70vh - 50px);
}

.signContainer{
  margin-top: 30px;
}

#root > div > div.signContainer > div:nth-child(2) > div > div{
  margin-bottom: 30px;
}

.main-card.dragging {
  border: 4px solid #007295;
  /* cambia el color según tu preferencia */
  background-color: #55adf581;
}
.main-card {
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.1s;
}
.drop-text {
  font-size: 3rem;
  font-weight: 600;
  color: #007295;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 4px solid #007295;
}

.hover-icon {
  position: absolute;
  top: 0px;
  right: 2px;
  padding: 0px;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.icons:hover .hover-icon {
  opacity: 1;
  transform: scale(1);
}


.hover-icon span {
  font-weight: 900;
  font-size: larger;
}

.checkbox-wrapper-22 .switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.checkbox-wrapper-22 .switch input {
  display: none;
}

.checkbox-wrapper-22 .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.checkbox-wrapper-22 .slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

.checkbox-wrapper-22 input:checked+.slider {
  background-color: #0d6efd;
}

.checkbox-wrapper-22 input:checked+.slider:before {
  transform: translateX(26px);
}

.checkbox-wrapper-22 .slider.round {
  border-radius: 34px;
}

.checkbox-wrapper-22 .slider.round:before {
  border-radius: 50%;
}




.background-login {
  flex: 4;
  background-image: url("../../public/img/cementerio.png");
  filter: brightness(70%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.d-lg-flex {
  display: flex;
  height: 100vh;
}

.login-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  z-index: 500;
}

.Auth-form-content {
  width: 100%;
  max-width: 100%;
  padding: 1rem;
}

.Auth-form-title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.form-control {
  font-size: 1rem;
  /* Tamaño del texto en los inputs */
  padding: 1rem;
  /* Espaciado interno de los inputs */
  border-radius: 4px;
  /* Bordes redondeados de los inputs */
  border: 1px solid #ced4da;
  /* Borde de los inputs */
}

.d-grid {
  width: 100%;
  /* Ajuste del ancho del botón */
}

.btn {
  font-size: 1.2rem;
  /* Tamaño del texto del botón */
  padding: 1rem;
  /* Espaciado interno del botón */
  border-radius: 4px;
  /* Bordes redondeados del botón */
}

#closeButton{
  display: none;
}

h4{
  margin-left: 30px;
}

#tableArchive > div > div > table > tbody > tr > td:nth-child(2){
  display: flex;
  justify-content: center;
}

#tableArchive > div > div > table > tbody > tr > td:nth-child(3){
  text-align: center;
}

#root > div > div.contenedor > div:nth-child(3) > div > div > div > div > div > div > div > div > div.d-flex.mb-1.col-6.col > label{
  justify-content: right;
  text-align: end;
}

.noShow{
  display: block!important;
}

.showCel{
  display: none!important;
}

@media (max-width: 767px) {
  .sidebar {
    transform: translateX(-250px);
  }

  #root>div>div.contenedor>nav>a {
    padding-left: 0;
  }

  #closeButton{
    display: block;
    position: relative;
    top: -80px;
    left: -20px;
  }


  #root>div>div.contenedor>div:nth-child(3)>div {
    padding: 0 20px;
  }

  #root>div>div.contenedor>div.mt-3.mb-3.row {
    padding: 10px 20px;
  }

  #root>div>div.contenedor>div:nth-child(3)>div>div>div.col>div>div>table>tbody>tr {
    font-size: 14px;
  }

  #root > div > div.contenedor > div:nth-child(3) > div > div.main-card > div > div > div > div > div > div.justify-content-end{
    justify-content: flex-start!important;
  }

  .archive-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    padding-left: 20px;
    padding-right: 20px;
  }

  #root>div>div.contenedor {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }


  #root>div>div.contenedor>nav>button {
    display: block;
  }

  .d-lg-flex {
    flex-direction: column;
  }

  .background-login {
    display: none;
  }

  .f-cel{
    font-size: 15px;
  }

  .css-19kzrtu{
    padding: 0!important;
  }

  .noShow{
    display: none!important;
  }

  .showCel{
    display: block!important;
  }
}

.login input {
  height: 4rem;
}

.login button {
  height: 4rem;
}

::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #d3d3d3; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #afafaf; 
}
.documents{
  min-height: 650px;
}